/* .banner {
    zoom: 105%;
    color: #fff;
    display: table;
    width: 100%;
    padding: 0;
    background: url(../img/Allina-banner.jpg) center center no-repeat;
    background-color: #e5e5e5;
    background-size: cover;
} */
.banner-heading{
    padding:250px 100px;
}

.bot-base {
    position: fixed;
    width: 65px;
    height: 65px;
    right: 25px;
    bottom: 10px;
    z-index: 9999;
}
.bot-round {
    background: url(persona.png) no-repeat;
    background-size: 100%;
    border: solid 3px Gray;
    border-radius: 100%;
    display: block;
    width: 65px;
    height: 65px;
    bottom: 25px;
    right: 25px;
    cursor: pointer;
}
.bot-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    max-width: 400px;
    height: calc(-100px + 100vh);
    background: #fff;
    box-shadow: 2px 1px 10px rgba(169,166,166,0.4);
    margin: 0 auto;
    position: fixed;
    top: -100%;
    right: 0;
    transition: all 0.5s;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 15px;
    overflow: hidden;
    z-index: 25000;
}
.bot-header {
    background-color: #686562;
    box-shadow: 0px 12px 16px -10px rgba(0,0,0,0.5);
}
.bot-header img{
    float:left;
    margin:5px;
}
.bot-header h5 {
    display: block;
    float: left;
    margin: 8px;
    color: #fff;
    /*padding-left:10px;*/
}
.bot-fly {
    margin: 5px;
    float: right;
    display: block;
    text-decoration: none;
    background: fixed;
    color: #FFF;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    vertical-align: middle;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.50);
    /* border-bottom: solid 1px #fff; */
    transition: 0.3s;
}

.bot-fly:active {
    -ms-transform: translateY(2px);
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
    border-bottom: none;
}

.bot-fly:hover {
    color: #000;
}
iframe {
    border-style: unset !important;
}

.header {
    display: flex;
    padding: 20px;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 5;
    background-color: transparent;
}



