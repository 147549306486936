@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import '~antd/dist/antd.css';

@font-face {
  font-family: "XXIIAven";
  src: local("XXIIAven"),
    url("./fonts/XXIIAven-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "XXIIAven";
  src: local("XXIIAven"),
    url("./fonts/XXIIAven-Medium.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "XXIIAven";
  src: local("XXIIAven"),
    url("./fonts/XXIIAven-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "XXIIAven";
  src: local("XXIIAven"),
    url("./fonts/XXIIAven-Light.ttf") format("truetype");
  font-weight: lighter;
}

body {
  color: #FFFFFF;
  background-color: #000000;
  font-family: 'XXIIAven', sans-serif;
}

.header {
  display: flex;
  padding: 20px;
  /* position: fixed; */
  top: 0;
  left: 0;
  /* width: 100vw; */
  box-sizing: border-box;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0);
}

 .ant-menu {
  background: none;
  color: white;
  border-bottom: none;
  float: right;
}

.header .ant-menu {
  float: right;
}

.menu-item, .ant-menu-horizontal > .ant-menu-item a {
  color: white;
}

.ant-menu-horizontal > .ant-menu-item a:hover, .ant-menu-item .ant-menu-item-selected a {
  color: #4DB4BD;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none;
 }

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu { 
  padding: 0 20px;
  margin: 0;
  /* border-right: white 1px solid; */
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected { 
  border-bottom: none;
}

.contact-us {
  background-color: #0D253F;
}

.footer-container {
  background-color: #0D253F;
  color: #FFFFFF;
  height: 170px;
}

.footer {
  padding: 20px;
  height: 100%;
  align-items: center;
}

.best-employee {
  font-family: 'Playfair Display'; 
  font-size: 3.3rem;
}

.bot-container {
  /* height: 400px; */
  background-image: url(landing_05.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.two-phases {
  font-family: 'Playfair Display';
  font-size: 3rem; 
  text-align: center;
}

.why-container {
  height: 620px;
  background-color: #000;
  background-image: url(why_slide.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  margin-top: -64px;
}

.solution-container {
  height: 620px;
  background-color: #000;
  background-image: url(solution_slide.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  margin-top: -64px;
}

.swift-allina {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: 90px;
  padding-top: 30px;
}

.smart-allina {
  display: flex; 
  flex-direction: column; 
  justify-content: right; 
  text-align: right; 
  padding-left: 90px;
  padding-top: 30px;
}

@media (min-width:900px) {
  .footer {
    background-image: url(allina-footer.svg);
    padding-left: 10%;
    background-repeat: round;
  }
}

@media (max-Width: 768px) {
  .engagement-middle {
    border: #fff 2px solid; 
    border-left: none;
    border-right: none;
  }
  .split-container {
    background-color: black; 
  }
  .swift-allina {
    background-image: url(about_mob_01.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    padding: 10px;
    padding-left: 30px;
    padding-top: 30px;
  }
  .smart-allina {
    background-image: url(about_mob_02.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    padding: 10px;
    padding-right: 30px;
    padding-top: 30px;
  }
  .b2b-container, .msp-container, .mid-container {
    border-radius: 5px;
    margin: 5px 0;
  }
  .best-employee {
    font-size: 2.5rem;
  }
  .bot-container {
    background-image: url(home_01.jpg);
  }
  .two-phases {
    font-size: 2rem;
  }
  .why-container {
    background-size: cover;
    background-position: center;
  }
  .solution-container {
    background-position: top;
  }
}

@media (min-Width: 769px) {
  .engagement-middle {
    border: #fff 2px solid; 
    border-top: none;
    border-bottom: none;
  }
  .split-container {
    padding-top: 60px;
    background-color: black; 
    background-image: url(about_03.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* height: 600px */
  }
  .b2b-container {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .msp-container {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .msp-inner-container {
    border-left: #fff 1px solid;
  }
  .b2b-inner-container {
    border-right: #fff 1px solid;
  }
}

.b2b-container, .msp-container, .mid-container {
  background-color: #031E32;
  text-align: center;
  cursor: pointer;
}

.selected-container {
  background-color: #194C9E;
}

.location-header {
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  color: #fff;
  font-weight: bold;
  padding-bottom: 50px;
}

.text-area {
  background-color: #2C6C7D;
  border: none;
  border-radius: 5px;
  resize: none;
  margin-bottom: 6px;
}

.text-area::placeholder {
  color: #fff;  
}

.text-input {
  height: 50px;
}

.send-button {
  background-color: #3B76D4; 
  width: 100%;
  color: #FFF;
  border: none;
  height: 40px;
  font-size: 1.1rem;
  border-radius: 5px;
}

.intelligent-txt {
  color: #09395E;
  font-family: 'Playfair Display';
  font-size: 3rem
}

.get-allina {
  /* height: 590px; */
  background-color: #0D253F;
  background-image: url(bg_get_allina.jpg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 50% auto;
  padding: 40px;
  padding-left: 0;
}

.experience-container {
  background-color: #000;
  background-image: url(slide-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.intelligent-container {
  background-color: #000;
  background-image: url(slide-3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.into-container {
  /* height: 540px; */
  background-color: #000;
  background-image: url(slide_01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  margin-top: -64px;
}

.model-container {
  background-color: #000;
  background-image: url(models_01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  margin-top: -64px;
}

.about-container {
  height: 540px;
  background-color: #000;
  background-image: url(about_01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  margin-top: -64px;
}

.idea-container {
  height: 620px;
  background-color: #000;
   background-image: url(use_slide.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  margin-top: -64px;
}

.allina-container {
  height: 620px;
  background-color: #000;
  background-image: url(about_02.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.contact-container {
  height: 230px;
  background-color: #000;
  background-image: url(contact__pg_slide.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  margin-top: -64px;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.sol-link {
  color: white;
}

.sol-link:hover {
  text-decoration: underline;
  color: white;
}

.icon-txt-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 120px;
  text-align: center;
  font-size: 1.1rem;
}

.brain-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  position: relative;
  justify-content: center;
}

.test1,.test2,.test3{
  /* position: absolute; */
  width: 350px;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 992px) { 
  .brain-container {
    padding-bottom: 450px
  }
  .container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 1020px;
  }
  .container img {
    margin: 50px auto;
    width: 550px;
  }
  .test1,.test2,.test3{
    position: absolute;
    width: 350px;
    display: flex;
    flex-direction: column;
  }
  .test1{
    top: 680px;
    left: 215px;
  }
  .test2{
    right: 60px;
    top: 10px;
  }
  .test3{
    top: 500px;
    left: 670px
  }
}


@media only screen and (max-width: 992px) {
  .container{
    width:600px;
  }
  .container img {
    display: none;
    width:350px;
  }
  .test1,.test2,.test3{
    padding: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
  .test2{
    /* background-position: center left; */
    background-image: url(why_brain_01.jpg);
  }
  .test1 {
    background-image: url(why_brain_03.jpg);
  }
  .test3 {
    background-image: url(why_brain_02.jpg);
  }
}

.ant-carousel .slick-dots-top {
  justify-content: flex-end;
  margin-right: 12px;
}

.ant-carousel .slick-dots li { 
  height: 10px;
  width: 10px;
}

.ant-carousel .slick-dots li button {
  height: 100%;
  border-radius: 5px;
  background-color: #3B76D4;
  opacity: 1;
}

.ant-carousel .slick-dots li.slick-active { 
  width: 10px;
}


.ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  font-size: 10px;
  font-weight: bold;
  color: #5387D9;
  padding: 0;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding: 0;
  padding-left: 10px;
}

.ant-input {
  border-radius: 20px;
  border-width: 2px !important;
  border-color: #5387D9;
}

.ant-form-item {
  margin-bottom: 10px;
}

.chat-hover {
  height: 80px; 
  width: 80px; 
  position: fixed; 
  z-index: 2000; 
  top: 50%; 
  right: 0; 
  margin-top: -25px; 
  margin-right: 3px;
}

.chat-hover:hover {
  height: 90px;
  width: 90px;
  margin-top: -30px;
}