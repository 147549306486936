.ant-upload-list.ant-upload-list-text {
    display: none;
}

.upload-box {
    padding: 15px; 
    background-color: rgba(255, 255, 255, 0.1); 
    border-radius: 5px;
    color: #fff;
    margin: auto;
}

.pdf-button {
    background-color: #3B76D4; 
    border-radius: 15px; 
    padding: 5px 25px; 
    font-weight: bold; 
    cursor: pointer;
}

.pdf-button.disabled {
    pointer-events: none;
    background-color: gray;
}

canvas.react-pdf__Page__canvas {
    width: 100% !important;
    object-fit: contain;
    height: auto !important;
}