body {
    overflow: auto !important;
    padding: 0px !important;
}

.react-container {
    width: 100%;
    height: 100%;
    position: absolute;
}

.chat-footer {
    width: 100%;
    height: 20px;
    position: fixed;
    bottom: 0;
    z-index: 100;
    background-color: #686562;
}

.dialog {
    position: fixed;
    bottom: 32px;
    z-index: 800;
    left: 30px;
    min-height: 50px;
    min-width: 75px;
    border-radius: 2px 2px 2px 2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;
    background: #424242;
    display: block;
}
.webchat-main {
    height: calc(-60px + 100vh);
}
.foot-footer {
    position: absolute;
    left: 0;
    background: #424242;
    color: #adadad;
    right: 0;
    bottom: 0;
    padding: 6px 18px;
    font-size: 14px;
    z-index: 100;
}
.main {
    position: fixed;
    bottom: 20px;
    width: 100%;
}


