@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "XXIIAven";
  src: local("XXIIAven"),
    url(/static/media/XXIIAven-Regular.8fa772c2.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "XXIIAven";
  src: local("XXIIAven"),
    url(/static/media/XXIIAven-Medium.b1dd0d01.ttf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "XXIIAven";
  src: local("XXIIAven"),
    url(/static/media/XXIIAven-Bold.2de22683.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "XXIIAven";
  src: local("XXIIAven"),
    url(/static/media/XXIIAven-Light.576323f6.ttf) format("truetype");
  font-weight: lighter;
}

body {
  color: #FFFFFF;
  background-color: #000000;
  font-family: 'XXIIAven', sans-serif;
}

.header {
  display: flex;
  padding: 20px;
  /* position: fixed; */
  top: 0;
  left: 0;
  /* width: 100vw; */
  box-sizing: border-box;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0);
}

 .ant-menu {
  background: none;
  color: white;
  border-bottom: none;
  float: right;
}

.header .ant-menu {
  float: right;
}

.menu-item, .ant-menu-horizontal > .ant-menu-item a {
  color: white;
}

.ant-menu-horizontal > .ant-menu-item a:hover, .ant-menu-item .ant-menu-item-selected a {
  color: #4DB4BD;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none;
 }

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu { 
  padding: 0 20px;
  margin: 0;
  /* border-right: white 1px solid; */
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected { 
  border-bottom: none;
}

.contact-us {
  background-color: #0D253F;
}

.footer-container {
  background-color: #0D253F;
  color: #FFFFFF;
  height: 170px;
}

.footer {
  padding: 20px;
  height: 100%;
  align-items: center;
}

.best-employee {
  font-family: 'Playfair Display'; 
  font-size: 3.3rem;
}

.bot-container {
  /* height: 400px; */
  background-image: url(/static/media/landing_05.18f57317.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.two-phases {
  font-family: 'Playfair Display';
  font-size: 3rem; 
  text-align: center;
}

.why-container {
  height: 620px;
  background-color: #000;
  background-image: url(/static/media/why_slide.8427ffb4.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  margin-top: -64px;
}

.solution-container {
  height: 620px;
  background-color: #000;
  background-image: url(/static/media/solution_slide.fd3e7bb0.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  margin-top: -64px;
}

.swift-allina {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: 90px;
  padding-top: 30px;
}

.smart-allina {
  display: flex; 
  flex-direction: column; 
  justify-content: right; 
  text-align: right; 
  padding-left: 90px;
  padding-top: 30px;
}

@media (min-width:900px) {
  .footer {
    background-image: url(/static/media/allina-footer.00c9172f.svg);
    padding-left: 10%;
    background-repeat: round;
  }
}

@media (max-Width: 768px) {
  .engagement-middle {
    border: #fff 2px solid; 
    border-left: none;
    border-right: none;
  }
  .split-container {
    background-color: black; 
  }
  .swift-allina {
    background-image: url(/static/media/about_mob_01.c9cde8ed.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    padding: 10px;
    padding-left: 30px;
    padding-top: 30px;
  }
  .smart-allina {
    background-image: url(/static/media/about_mob_02.e765496a.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    padding: 10px;
    padding-right: 30px;
    padding-top: 30px;
  }
  .b2b-container, .msp-container, .mid-container {
    border-radius: 5px;
    margin: 5px 0;
  }
  .best-employee {
    font-size: 2.5rem;
  }
  .bot-container {
    background-image: url(/static/media/home_01.1cb2cdae.jpg);
  }
  .two-phases {
    font-size: 2rem;
  }
  .why-container {
    background-size: cover;
    background-position: center;
  }
  .solution-container {
    background-position: top;
  }
}

@media (min-Width: 769px) {
  .engagement-middle {
    border: #fff 2px solid; 
    border-top: none;
    border-bottom: none;
  }
  .split-container {
    padding-top: 60px;
    background-color: black; 
    background-image: url(/static/media/about_03.60e60425.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* height: 600px */
  }
  .b2b-container {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .msp-container {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .msp-inner-container {
    border-left: #fff 1px solid;
  }
  .b2b-inner-container {
    border-right: #fff 1px solid;
  }
}

.b2b-container, .msp-container, .mid-container {
  background-color: #031E32;
  text-align: center;
  cursor: pointer;
}

.selected-container {
  background-color: #194C9E;
}

.location-header {
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  color: #fff;
  font-weight: bold;
  padding-bottom: 50px;
}

.text-area {
  background-color: #2C6C7D;
  border: none;
  border-radius: 5px;
  resize: none;
  margin-bottom: 6px;
}

.text-area::-webkit-input-placeholder {
  color: #fff;  
}

.text-area:-ms-input-placeholder {
  color: #fff;  
}

.text-area::placeholder {
  color: #fff;  
}

.text-input {
  height: 50px;
}

.send-button {
  background-color: #3B76D4; 
  width: 100%;
  color: #FFF;
  border: none;
  height: 40px;
  font-size: 1.1rem;
  border-radius: 5px;
}

.intelligent-txt {
  color: #09395E;
  font-family: 'Playfair Display';
  font-size: 3rem
}

.get-allina {
  /* height: 590px; */
  background-color: #0D253F;
  background-image: url(/static/media/bg_get_allina.9851796b.jpg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 50% auto;
  padding: 40px;
  padding-left: 0;
}

.experience-container {
  background-color: #000;
  background-image: url(/static/media/slide-2.6776e561.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.intelligent-container {
  background-color: #000;
  background-image: url(/static/media/slide-3.04a1ce27.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.into-container {
  /* height: 540px; */
  background-color: #000;
  background-image: url(/static/media/slide_01.d9773608.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  margin-top: -64px;
}

.model-container {
  background-color: #000;
  background-image: url(/static/media/models_01.b451ecfb.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  margin-top: -64px;
}

.about-container {
  height: 540px;
  background-color: #000;
  background-image: url(/static/media/about_01.d406a54b.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  margin-top: -64px;
}

.idea-container {
  height: 620px;
  background-color: #000;
   background-image: url(/static/media/use_slide.f83f2ae7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  margin-top: -64px;
}

.allina-container {
  height: 620px;
  background-color: #000;
  background-image: url(/static/media/about_02.b5ba9714.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.contact-container {
  height: 230px;
  background-color: #000;
  background-image: url(/static/media/contact__pg_slide.60c62734.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  margin-top: -64px;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.sol-link {
  color: white;
}

.sol-link:hover {
  text-decoration: underline;
  color: white;
}

.icon-txt-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 120px;
  text-align: center;
  font-size: 1.1rem;
}

.brain-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  position: relative;
  justify-content: center;
}

.test1,.test2,.test3{
  /* position: absolute; */
  width: 350px;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 992px) { 
  .brain-container {
    padding-bottom: 450px
  }
  .container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 1020px;
  }
  .container img {
    margin: 50px auto;
    width: 550px;
  }
  .test1,.test2,.test3{
    position: absolute;
    width: 350px;
    display: flex;
    flex-direction: column;
  }
  .test1{
    top: 680px;
    left: 215px;
  }
  .test2{
    right: 60px;
    top: 10px;
  }
  .test3{
    top: 500px;
    left: 670px
  }
}


@media only screen and (max-width: 992px) {
  .container{
    width:600px;
  }
  .container img {
    display: none;
    width:350px;
  }
  .test1,.test2,.test3{
    padding: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
  .test2{
    /* background-position: center left; */
    background-image: url(/static/media/why_brain_01.4845cec1.jpg);
  }
  .test1 {
    background-image: url(/static/media/why_brain_03.4ce3be1e.jpg);
  }
  .test3 {
    background-image: url(/static/media/why_brain_02.42248902.jpg);
  }
}

.ant-carousel .slick-dots-top {
  justify-content: flex-end;
  margin-right: 12px;
}

.ant-carousel .slick-dots li { 
  height: 10px;
  width: 10px;
}

.ant-carousel .slick-dots li button {
  height: 100%;
  border-radius: 5px;
  background-color: #3B76D4;
  opacity: 1;
}

.ant-carousel .slick-dots li.slick-active { 
  width: 10px;
}


.ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  font-size: 10px;
  font-weight: bold;
  color: #5387D9;
  padding: 0;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding: 0;
  padding-left: 10px;
}

.ant-input {
  border-radius: 20px;
  border-width: 2px !important;
  border-color: #5387D9;
}

.ant-form-item {
  margin-bottom: 10px;
}

.chat-hover {
  height: 80px; 
  width: 80px; 
  position: fixed; 
  z-index: 2000; 
  top: 50%; 
  right: 0; 
  margin-top: -25px; 
  margin-right: 3px;
}

.chat-hover:hover {
  height: 90px;
  width: 90px;
  margin-top: -30px;
}
.ant-upload-list.ant-upload-list-text {
    display: none;
}

.upload-box {
    padding: 15px; 
    background-color: rgba(255, 255, 255, 0.1); 
    border-radius: 5px;
    color: #fff;
    margin: auto;
}

.pdf-button {
    background-color: #3B76D4; 
    border-radius: 15px; 
    padding: 5px 25px; 
    font-weight: bold; 
    cursor: pointer;
}

.pdf-button.disabled {
    pointer-events: none;
    background-color: gray;
}

canvas.react-pdf__Page__canvas {
    width: 100% !important;
    object-fit: contain;
    height: auto !important;
}
body {
    overflow: auto !important;
    padding: 0px !important;
}

.react-container {
    width: 100%;
    height: 100%;
    position: absolute;
}

.chat-footer {
    width: 100%;
    height: 20px;
    position: fixed;
    bottom: 0;
    z-index: 100;
    background-color: #686562;
}

.dialog {
    position: fixed;
    bottom: 32px;
    z-index: 800;
    left: 30px;
    min-height: 50px;
    min-width: 75px;
    border-radius: 2px 2px 2px 2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;
    background: #424242;
    display: block;
}
.webchat-main {
    height: calc(-60px + 100vh);
}
.foot-footer {
    position: absolute;
    left: 0;
    background: #424242;
    color: #adadad;
    right: 0;
    bottom: 0;
    padding: 6px 18px;
    font-size: 14px;
    z-index: 100;
}
.main {
    position: fixed;
    bottom: 20px;
    width: 100%;
}



/* .banner {
    zoom: 105%;
    color: #fff;
    display: table;
    width: 100%;
    padding: 0;
    background: url(../img/Allina-banner.jpg) center center no-repeat;
    background-color: #e5e5e5;
    background-size: cover;
} */
.banner-heading{
    padding:250px 100px;
}

.bot-base {
    position: fixed;
    width: 65px;
    height: 65px;
    right: 25px;
    bottom: 10px;
    z-index: 9999;
}
.bot-round {
    background: url(/static/media/persona.78ae1f59.png) no-repeat;
    background-size: 100%;
    border: solid 3px Gray;
    border-radius: 100%;
    display: block;
    width: 65px;
    height: 65px;
    bottom: 25px;
    right: 25px;
    cursor: pointer;
}
.bot-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    max-width: 400px;
    height: calc(-100px + 100vh);
    background: #fff;
    box-shadow: 2px 1px 10px rgba(169,166,166,0.4);
    margin: 0 auto;
    position: fixed;
    top: -100%;
    right: 0;
    transition: all 0.5s;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 15px;
    overflow: hidden;
    z-index: 25000;
}
.bot-header {
    background-color: #686562;
    box-shadow: 0px 12px 16px -10px rgba(0,0,0,0.5);
}
.bot-header img{
    float:left;
    margin:5px;
}
.bot-header h5 {
    display: block;
    float: left;
    margin: 8px;
    color: #fff;
    /*padding-left:10px;*/
}
.bot-fly {
    margin: 5px;
    float: right;
    display: block;
    text-decoration: none;
    background: fixed;
    color: #FFF;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    vertical-align: middle;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.50);
    /* border-bottom: solid 1px #fff; */
    transition: 0.3s;
}

.bot-fly:active {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
    border-bottom: none;
}

.bot-fly:hover {
    color: #000;
}
iframe {
    border-style: unset !important;
}

.header {
    display: flex;
    padding: 20px;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 5;
    background-color: transparent;
}




